.icon-contact {
    position: relative;
    font-size: 35px !important;
    margin-right: 12px;
    top: 3px;
}
.icon-contact-home {
    position: relative;
    top: 8px;
}
.aditional-contact-link {
    position: relative;
    top: 11px;
    padding-right: 15px;
}

@media (min-width: 821px) {
    .icon-contact {
        font-size: 40px !important;
        top: 8px;
    }
}
