.item-section3-titles {
    position: relative;
}
.item-section3-titles-years{
    position: relative;
    top: -18px;
}
.item-section3 ul {
    position: relative;
    top: -25px;
}
.item-section3-desc {
    padding: 0px 15px;

}