.container {
  position: relative;
}
.container-grid {
  width: 100%;
  height: 100%;
}
.colorize {
  position: fixed;
  bottom: 15px;
  right: 15px;

}
.section1-names-fullname {
  font-size: '7rem';
  position: relative;
}
.section1-names-lastname {
  position: relative;
  top: -37px;
}
.section-contacts {
  height: 100%;
}
.section1-contacts-item {
  padding: 10px 0;
}
.skill-item {
  width: 100%;
  /* height: 30px; */
  padding: 20px;
  display: inline-block;
}

/* 
value         |0px     600px    960px    1280px   1920px
key           |xs      sm       md       lg       xl
screen width  |--------|--------|--------|--------|-------->
range         |   xs   |   sm   |   md   |   lg   |   xl */

.section-item { margin-bottom: 50px !important; }
.container { padding-top: 10px; padding-left: 10px; padding-right: 10px;}
.item-names {max-height: 200px;}
.item-contacts { }
.item-about {}
.item-skills { position: relative; }
.item-experiences {}


@media (min-width: 600px) {
  .section-item { }
  .container { padding-left: 20px; padding-right: 20px;}
  .item-names { max-height: auto; }
  .item-contacts {}
  .item-about { }
  .item-skills {  }
  .skill-item { width: auto; }
  .item-experiences {}
}

@media (min-width: 960px) {
  .section-item { }
  .item-names {}
  .item-contacts {}
  .item-about {}
  .item-skills { }
  .item-experiences {}
}

@media (min-width: 1280px) {
  .section-item { margin-bottom: 0px !important; }
  .item-names {}
  .item-contacts {}
  .item-about {}
  .item-skills { position: relative; top: 28px }
  .item-experiences { margin-top: 80px !important; position: relative;  }
}

@media (min-width: 1920px) {
  .section-item {}
  .item-names {}
  .item-contacts {}
  .item-about {}
  .item-skills {  }
  .item-experiences {}
}


